import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'csoft-library/servizi';
import {
  catchError,
  filter,
  Observable,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const AuthSvc = inject(AuthService);

  const token: string = localStorage.getItem('AuthToken');

  let updatedReq;

  // Per evitare check aggiuntivi lato backend
  if (token) {
    updatedReq = req.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  } else {
    updatedReq = req.clone();
  }

  return next(updatedReq).pipe(
    catchError((event) => {
      if (event.status === 600) {
        if (!AuthSvc.isRefreshing) {
          AuthSvc.isRefreshing = true;

          AuthSvc.refreshToken$.next(null);

          // Refresho token
          return AuthSvc.RefreshToken().pipe(
            switchMap((newToken: string) => {
              AuthSvc.isRefreshing = false;
              AuthSvc.refreshToken$.next(newToken);

              // Aggiorno header
              const retryReq = req.clone({
                setHeaders: {
                  // UsrName: username,
                  Authorization: `Bearer ${newToken}`,
                },
              });

              return next(retryReq);
            }),
            catchError((err) => {
              AuthSvc.isRefreshing = false;

              console.error('Refresh token failed, logging out...', err);

              AuthSvc.LogOutUser();

              return of(err);
            })
          );
        } else {
          // Wait for the refresh to complete
          return AuthSvc.refreshToken$.pipe(
            filter((newToken) => newToken != null), // Wait until a non-null token is emitted
            take(1), // Only take the first emission
            switchMap((newToken) => {
              // Retry the original request with the new token
              const retryReq = req.clone({
                setHeaders: { Authorization: `Bearer ${newToken}` },
              });
              return next(retryReq);
            })
          );
        }
      } else {
        return of(event);
      }
    })
  );
}
