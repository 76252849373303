import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pagine/home/home.component';
import { authGuard, authGuardLogIn } from 'csoft-library/auth';
import { LoginComponent } from 'csoft-library/componenti/login';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  {
    path: 'home',
    pathMatch: 'prefix',
    component: HomeComponent,
    canActivate: [authGuard],
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'Roles',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./pagine/Ruoli/ruoli.module').then((lz) => lz.RuoliModule),
  },
  {
    path: 'Users',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./pagine/Utenti/utenti.module').then((lz) => lz.UtentiModule),
  },
  {
    path: 'Levels',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./pagine/TerziLivelli/terzi-livelli.module').then(
        (lz) => lz.TerziLivelliModule
      ),
  },
  {
    path: 'Category',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./pagine/Categorie/categorie.module').then(
        (lz) => lz.CategorieModule
      ),
  },
  {
    path: 'Pages',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./pagine/Pagine/pagine.module').then((lz) => lz.PagineModule),
  },
  {
    path: 'Notifiche',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./pagine/Notifiche/notifiche.module').then(
        (lz) => lz.NotificheModule
      ),
  },
  {
    path: 'Log',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./pagine/Log/log.module').then((lz) => lz.LogModule),
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
