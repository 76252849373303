import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SliderComponent } from 'csoft-library/componenti/slider';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [SliderComponent, FormsModule],
})
export class HomeComponent {}
